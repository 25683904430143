import './Success.scss';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useContext } from 'react';
import { ReturnContext } from '../../context/ReturnContext';

function Success() {
  const { t, i18n } = useTranslation();
  const { returnProducts } = useContext(ReturnContext);
  // we treat message as no defective if auto approve is not enabled
  let messageTitle = "success.title_no_defective";
  let messageText = "success.text_no_defective";

  const autoApproveReasons = process.env.REACT_APP_MANUAL_APPROVE?.split(',');

  if (autoApproveReasons?.length) {
    const returnLineItemObjectNotDefective = returnProducts
      .filter((returnProduct) => {
        return !autoApproveReasons.includes(returnProduct.returnReason)
      });

    const returnLineItemObjectDefective = returnProducts
    .filter((returnProduct) => {
      return autoApproveReasons.includes(returnProduct.returnReason)
    });

    if(!returnLineItemObjectDefective.length) {
      messageTitle = 'success.title_no_defective';
      messageText = 'success.text_no_defective';
    } else if (returnLineItemObjectNotDefective.length) {
      messageTitle = 'success.title_mix_defective';
      messageText = 'success.text_mix_defective';
    } else {
      messageTitle = 'success.title_defective';
      messageText = 'success.text_defective';
    }
  }

  useEffect(() => {
    var lang = 'it';
    if (window.location.search) {
      window.location.search.split('?')[1].split('&').forEach(arg => {
        const splitArg = arg.split('=');
        if (splitArg.length > 0 && splitArg[0] === 'lang') {
          lang = splitArg[1];
        }
      });
      i18n.changeLanguage(lang);
    }

    window.parent.postMessage('page:success', {
      targetOrigin: process.env.REACT_APP_PARENT_HOST
    });
  }, []);

  return (
    <div className='return-success__container'>
      <h1 className='return-success__title'>{t(messageTitle)}</h1>
      <p className='return-success__text'>{t(messageText)}</p>
    </div>
  )
}

export default Success
